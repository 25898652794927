import Row from "react-bootstrap/Row";
import style from "./paymentSuccess.module.scss";
import Col from "react-bootstrap/Col";
import {GatsbySrcImage} from "../components/GatsbyImage";
import Container from "react-bootstrap/Container";
import React, { Fragment } from "react";
import {graphql, useStaticQuery} from "gatsby";
import Layout from "../components/Layout";
import Img from "gatsby-image"

const getData = graphql`
      query {
        paymentFailure: file(relativePath: { eq: "404-robot.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920, pngQuality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `

const PaymentSuccess = ({resultMessage}) => {

    const data = useStaticQuery(getData);
    let errorMessage = null;

    console.log('result', resultMessage)
    if(resultMessage) {
        errorMessage = resultMessage;
    }
    return (
      <Layout
        seoComponent={{
          seoTitle: "payment failure",
          seoDescription: "payment failure",
        }}
        location={{ pathname: "payment-failure" }}
      >
        <Container>
          <Row className={`${style.thankYouRow} d-flex justify-content-center`}>
            <Col
              lg={10}
              xl={8}
              className="d-flex flex-column align-items-center"
            >
              <h1 className="mt-5 pt-5">Sorry, something went wrong</h1>
              {errorMessage && (
                <Fragment>
                  <p className="h5 py-3 py-md-4 py-lg-5">
                    Reason for failure: {errorMessage}
                  </p>
                </Fragment>
              )}
              <p>
                <a className="h5 text-secondary" href="/">
                  Return to homepage
                </a>
              </p>
              <div className={`${style.image} py-3 py-md-4 py-lg-5`}>
                <GatsbySrcImage
                  img={data.paymentFailure}
                  className={`w-100 h-100`}
                  altText="Bib icon with a heart in the middle"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    )

}
export default PaymentSuccess
